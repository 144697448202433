<template>
  <b-overlay
      :show="products === null"
      rounded="sm"
  >

    <div v-if="products !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <h1 class="mb-2">دوره ها</h1>

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchCommand"
                    class="d-inline-block mr-1"
                    placeholder="جستجو..."
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="products"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: product.price -->
          <template #cell(product.price)="data">
            <span v-if="data.item.product.isFree">رایگان</span>
            <span v-else>{{makePrettyPrice(data.item.product.price)}} تومان</span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link :to="{ name: 'apps-e-commerce-studentInfoes', params: { id: data.item.product.productId } }">
              <feather-icon icon="EditIcon" size="20"/>
            </b-link>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

<!--      <b-card-->
<!--          no-body-->
<!--          class="my-2"-->
<!--      >-->

<!--        <h1 class="m-2">سمینار ها</h1>-->

<!--        <b-table-->
<!--            ref="refUserListTable"-->
<!--            class="position-relative"-->
<!--            :items="seminars"-->
<!--            responsive-->
<!--            :fields="myTableColumns2"-->
<!--            primary-key="id"-->
<!--            show-empty-->
<!--            bordered-->
<!--            striped-->
<!--            empty-text="موردی موجود نیست!"-->
<!--        >-->

<!--          &lt;!&ndash; Column: price &ndash;&gt;-->

<!--          <template #cell(price)="data">-->

<!--            <div class=" d-flex flex-row ">-->
<!--              <span class="text-primary">{{makePrettyPrice(data.item.price)}} تومان</span>-->
<!--            </div>-->

<!--          </template>-->

<!--          &lt;!&ndash; Column: startDate &ndash;&gt;-->

<!--          <template #cell(startDateTime)="data">-->

<!--            <small class="">-->
<!--              {{data.item.startDateTime.slice(11,16)}}-->
<!--              - -->
<!--              {{createJalaliDate(data.item.startDateTime.slice(0,10))}}-->
<!--            </small>-->

<!--          </template>-->

<!--          &lt;!&ndash; Column: endDate &ndash;&gt;-->

<!--          <template #cell(endDateTime)="data">-->

<!--            <small class="">-->
<!--              {{data.item.endDateTime.slice(11,16)}}-->
<!--              - -->
<!--              {{createJalaliDate(data.item.endDateTime.slice(0,10))}}-->
<!--            </small>-->

<!--          </template>-->

<!--          &lt;!&ndash; Column: createDate &ndash;&gt;-->

<!--          <template #cell(showTime)="data">-->

<!--            <small class="">-->
<!--              {{data.item.showTime.slice(11,16)}}-->
<!--              - -->
<!--              {{createJalaliDate(data.item.showTime.slice(0,10))}}-->
<!--            </small>-->

<!--          </template>-->

<!--          &lt;!&ndash; Column: delete &ndash;&gt;-->

<!--          <template #cell(delete)="data">-->

<!--            <div class="cursor-pointer d-flex flex-row"-->
<!--                 v-b-modal.modal-delete-->
<!--                 @click="showModal(data.item.seminarId)"-->
<!--            >-->
<!--              <feather-icon icon="TrashIcon" size="20" class="text-danger" />-->
<!--            </div>-->

<!--          </template>-->

<!--          <template #cell(actions)="data">-->
<!--            <b-link :to="{ name: 'apps-seminar-members', params: { id: data.item.seminarId }}">-->
<!--              <feather-icon icon="ClipboardIcon" size="20"/>-->
<!--            </b-link>-->
<!--          </template>-->

<!--          <template #cell(edit)="data">-->
<!--            <b-link :to="{ name: 'apps-seminar-edit', params: { id: data.item.seminarId }}">-->
<!--              <feather-icon icon="EditIcon" size="20"/>-->
<!--            </b-link>-->
<!--          </template>-->

<!--          <template #cell(setReserve)="data">-->
<!--            <b-link :to="{ name: 'apps-seminar-reserve', params: { id: data.item.seminarId }}">-->
<!--              <feather-icon icon="UserPlusIcon" size="20"/>-->
<!--            </b-link>-->
<!--          </template>-->

<!--          <template #cell(setPrice)="data">-->
<!--            <b-link :to="{ name: 'apps-seminar-setPrice', params: { id: data.item.seminarId }}">-->
<!--              <feather-icon class="text-success" icon="DollarSignIcon" size="20"/>-->
<!--            </b-link>-->
<!--          </template>-->


<!--        </b-table>-->
<!--        <div class="mx-2 mb-2">-->
<!--          <b-row>-->

<!--            <b-col-->
<!--                cols="12"-->
<!--                sm="6"-->
<!--                class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
<!--            >-->
<!--              &lt;!&ndash;            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>&ndash;&gt;-->
<!--            </b-col>-->
<!--            &lt;!&ndash; Pagination &ndash;&gt;-->

<!--          </b-row>-->
<!--        </div>-->
<!--      </b-card>-->
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Helper from "@/libs/Helper";
import {OrderGetProductsForStudents} from "@/libs/Api/Order";
import {SeminarGetAllRequest} from "@/libs/Api/Seminar";

export default {
  title:"لیست دانشجویان - پنل ادمین مکس ",
  name: "StudentsList",
  data(){
    return{
      products:null,
      currentPage:1,
      currentPage2:1,
      seminars:null,
      totalCounts:null,
      totalCount2:null,
      perPage:10,
      perPage2:10,
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'product.productId' ,label :'شناسه محصول'},
        { key: 'product.title' ,label :'نام محصول'},
        { key: 'product.price' ,label :'قیمت'},
        { key: 'usersRegistered' ,label :'تعداد دانشجویان'},
        { key: 'actions',label:'نمایش دانشجویان'},
      ],
      myTableColumns2 : [
        { key: 'title',label:'عنوان'},
        { key: 'owner',label:'برگزار کننده'},
        { key: 'startDateTime',label:'تاریخ و زمان شروع'},
        { key: 'endDateTime',label:'تاریخ و زمان پایان'},
        { key: 'price',label:'قیمت'},
        { key: 'showTime',label:'تاریخ ثبت نام'},
        { key: 'reservesCount',label:'تعداد صندلی های رزرو شده'},
        { key: 'actions',label:'دانشجویان'},
      ],
      searchCommand:'',
      isAddNewUserSidebarActive:false,
    }
  },
  async created() {
    await this.getProducts(this.perPage,this.currentPage,this.searchCommand)
    // await this.getAllSeminars()
  },
  methods:{
    refreshData(){
      this.getProducts(this.perPage,this.currentPage,this.searchCommand)
    },
    async getProducts(count,pageNumber,searchCommand){
      let _this = this;
      let data = {
        pageNumber:pageNumber,
        count:count,
        searchCommand:searchCommand
      }

      let orderGetProductsForStudents = new OrderGetProductsForStudents(_this);
      orderGetProductsForStudents.setParams(data);
      await orderGetProductsForStudents.fetch(function (content){
        _this.products = content.result;
        _this.totalCounts = content.productsCount;
        console.log(content)
      },function (error){
        console.log(error);
      })
    },
    async getAllSeminars(pageNumber=0,count=0){
      let  _this = this;
      let  defaultGetData={
        pageNumber:pageNumber,
        count:count,
      }

      let seminarGetAllRequest = new SeminarGetAllRequest(_this);
      seminarGetAllRequest.setParams(defaultGetData);
      await seminarGetAllRequest.fetch(function (content){
        _this.seminars = content.seminars;
        _this.totalCount2 = content.seminarsCount;
      },function (error){
        console.log(error)
      })
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
  },
  watch:{
    perPage:function (nv,ov){
      this.getProducts(nv,this.currentPage,this.searchCommand);
    },
    currentPage:function (nv,ov) {
      this.getProducts(this.perPage,nv,this.searchCommand);
    },
    searchCommand:function (nv,ov){
      this.getProducts(this.perPage,this.currentPage,nv)
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>